import React, { useEffect } from 'react';
import { useAgile } from '@agile-ts/react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import DisableDevtool from 'disable-devtool';
import AppRouter from './routing/AppRouter';
import ThemeProvider from './ui/theme/ThemeProvider';
import { ui, user } from './core';
import { ProvideData } from './context';

DisableDevtool({
  url: 'https://newrelic.com',
});

// UI-Themes
const themes = {
  [ui.themes.dark.type]: ui.themes.dark.theme,
};

const App: React.FC = () => {
  const activeTheme = useAgile(ui.THEME_TYPE);

  // TODO remove or change to some other auth sys eg. linkedin
  useEffect(() => {
    user.fetchAuthenticatedUser();
  }, []);

  return (
    <ThemeProvider activeThemeKey={activeTheme} themes={themes}>
      {(theme) => (
        <StyledThemeProvider theme={theme}>
          <ProvideData>
            <AppRouter />
          </ProvideData>
        </StyledThemeProvider>
      )}
    </ThemeProvider>
  );
};

export default App;
